export const FIND_ESTABLISHMENT_ADMIN_REQUEST_STATUS_BY_ID = `query findEstablishmentAdminRequestStatus($id: Int!) {
  findEstablishmentAdminRequest(id: $id) {
    status
  }
}`;

export const FIND_ENTITY_BY_ID = `query FindEntityById($id: Int!) {
  findEntityById(id: $id) {
    isNama,
    type,
    copiedFrom,
    autocreateMembershipForOwner,
    id,
    status,
    createdAt,
    unifiedNumber700,
    updatedAt,
    ceoCustomCondition,
    owner {
      firstName,
      fatherName,
      grandfatherName,
      lastName,
      nationalId,
      id,
    },
    nameArabic,
    nameEnglish,
    typeOfBenefits,
    boardFirstTermLength,
    boardLaterTermsLength,
    classificationDetails {
      departmentId,
      departmentArTitle,
      departmentEnTitle
      firstSubClassificationId,
      firstSubClassificationArTitle,
      firstSubClassificationEnTitle,
      secondSubClassificationId,
      secondSubClassificationArTitle,
      secondSubClassificationEnTitle,
      mainClassificationId,
      mainClassificationArTitle,
      mainClassificationEnTitle,
    },
    membershipRequirements,
    membershipType,
    activityIds,
    activities {
      arTitle,
    },
    goalIds,
    goals {
      ...on CommonGoal {
        arTitle,
      }
    }
    ceoEducationalLevelCondition,
    ceoPreviousExperienceCondition,
    ceoCustomCondition,
    capital,
    capitalReductionPercentage,
    numberOfShares,
    minNumOfSharesPerMember,
    regionCode,
    region {
      arTitle,
      enTitle,
      code,
    },
    city {
      arTitle,
      enTitle,
      regionCode,
      uid,
    },
    cityUid,
    numberOfBoardMembers,
    shareValue,
    membershipRequirements,
    eligibilityStudyFiles {
      path,
      filename,
      uid,
    },
    supportingDocuments {
      filename,
      docType,
      fileSize,
      uid,
      path,
    },
    entityLicenses {
      uid
    }
    bankCertificates {
      docType,
      fileSize,
      filename,
      path,
      uid,
    },
    entityPolicies {
      uid
    }
    establishmentDecisions {
      uid
    }
    migrationDelegateLetters {
      uid
    }
    entityUid
  }
}`;

export const LIST_CONTRIBUTION_SOURCES = `query ListContributionSources($entityId: Int, $page: Int, $size: Int) {
  listContributionSources(entityId: $entityId, page: $page, size: $size) {
    contributionsSources {
      amount,
      title,
      kind,
      contributionProofs {
        filename,
        docType,
        fileSize,
        uid,
        path,
      }
    }
  }
}`;

export const FIND_TEMP_BANK_ACCOUNT_BY_ENTITY_ID = `query FindTempBankAccountByEntityId($entityId: Int!) {
  findTempBankAccountByEntityId(entityId: $entityId) {
    iban
  }
}`;

export const FIND_BRANCH_BY_ID = `query FindBranchById($id: Int!) {
  findBranchById(id: $id) {
    nameArabic,
    nameEnglish,
    status,
    goals {
      ...on CommonGoal {
        arTitle,
      }
    }
    region {
      arTitle,
      enTitle,
    },
    city {
      arTitle,
      enTitle,
    }
    proofFile{
      filename
      path
      uid
    }
    approvalFile{
      filename
      path
      uid
    }
  }
}`;

export const ENTITY_LOCATIONS = `query EntityLocations($page: Int, $size: Int, $entityId: Int!) {
  entityLocations(page: $page, size: $size, entityId: $entityId) {
    entityLocations {
      region {
        enTitle
        arTitle
        code
      }
      regionCode
      cityUids
    }
  }
}`;

export const CREATE_OR_UPDATE_ENTITY_LOCATIONS = `mutation createOrUpdateEntityLocations($entityLocationsCreateOrUpdateAttributes: EntityLocationsCreateOrUpdateInput!) {
  createOrUpdateEntityLocations(entityLocationsCreateOrUpdateAttributes: $entityLocationsCreateOrUpdateAttributes) {
    entityId
  }
}`;

export const UPDATE_ENTITY_BY_SUPERVISORY = `mutation updateEntityBySupervisory($id: Int!, $nameArabic: String, $nameEnglish: String, $goalIds: [Int!], $activityIds: [Int!]) {
  updateEntityBySupervisory(id: $id, nameArabic: $nameArabic, nameEnglish: $nameEnglish, goalIds: $goalIds, activityIds: $activityIds) {
    id
    nameArabic
    nameEnglish
    goalIds
    activityIds
    status
  }
}`;
